<template>
  <LSection>
    <template #body>
      <div class="activateAccountWithToken">
        <TokenStatus
          class="hidden"
          operation="ACTIVATE_ACCOUNT"
          :token="activateAccountToken"
          tokenName="activateAccountToken"
          :check-immediately="false"
          @token-is-valid="onValidToken"
          @token-is-invalid="() => (tokenIsValid = false)"
          @token-has-expired="() => (tokenIsValid = false)"
        ></TokenStatus>
        <div v-if="showForm">
          <div class="flex justify-center">
            <form @submit.prevent="onSubmit" ref="form" class="form">
              <h1 class="heading1">
                Activate Account
                <hr class="gradientLine" />
              </h1>
              <div>
                <label for="name">Full name</label>
                <FullName @change="onNameChange"></FullName>
                <LFieldError :message="inputError.name"></LFieldError>
              </div>
              <div>
                <label for="password">Password</label>
                <div class="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 bg-gray-200 rounded-full p-1 m-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                    />
                  </svg>
                  <TextInput
                    :tabIndex="3"
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Your password"
                    @change="onPasswordChange"
                  ></TextInput>
                </div>
                <LFieldError :message="inputError.password" class="w-full">
                </LFieldError>
              </div>
              <div>
                <label for="passwordRepeat">Confirm Password</label>
                <div class="flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 bg-gray-200 rounded-full p-1 m-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                    />
                  </svg>
                  <TextInput
                    :tabIndex="4"
                    id="passwordRepeat"
                    name="passwordRepeat"
                    type="password"
                    placeholder="Repeat your password"
                    @input="onPasswordRepeatChange"
                    @change="onPasswordRepeatChange"
                  >
                  </TextInput>
                </div>
                <LFieldError :message="inputError.passwordRepeat"></LFieldError>
              </div>
              <div @click="() => onAcceptTermsChange(!userAccount.acceptTerms)">
                <input
                  class="mr-1 cursor-pointer"
                  type="checkbox"
                  :tabindex="5"
                  :checked="userAccount.acceptTerms"
                />
                <label class="cursor-pointer"
                  >Accept terms and conditions</label
                >
              </div>
              <div
                class="w-full"
                @click="
                  () =>
                    onAcceptPrivacyPolicyChange(
                      !userAccount.acceptPrivacyPolicy
                    )
                "
              >
                <input
                  class="mr-1"
                  type="checkbox"
                  :tabindex="6"
                  :checked="userAccount.acceptPrivacyPolicy"
                />
                <label class="cursor-pointer">Accept privacy policy</label>
              </div>

              <LActionError
                v-if="actionError"
                class="mb-4"
                :message="actionError"
              ></LActionError>

              <div class="items-center justify-center flex flex-col">
                <Button
                  type="submit"
                  :tabindex="8"
                  :is-primary="isValid"
                  :is-disabled="!isValid"
                  style="
                    background-image: linear-gradient(
                      to right,
                      #db2777,
                      #f97316
                    );
                  "
                  @click="onSubmit"
                >
                  <template #right>Register</template>
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div v-if="!showForm">
        <h1 class="heading1">
          Thanks for registering your account
          <hr class="gradientLine" />
        </h1>
        <p class="text-sm k15:text-lg text-gray-600">Please login</p>
      </div>
    </template>
  </LSection>
</template>

<script>
import { computed, reactive, ref, watch, watchPostEffect } from "vue";
import TokenStatus from "@/components/authentication/TokenStatus.vue";
import { useStatusMessage } from "@/composables/useStatusMessage";
import { ACTIVATE_ACCOUNT } from "@/store/operations";
import FullName from "@/components/forms/FullName.vue";
import LFieldError from "@/components/layout/LFieldError.vue";
import TextInput from "@/components/forms/TextInput.vue";
import LActionError from "@/components/layout/LActionError.vue";
import Button from "@/components/forms/Button.vue";
import { usePassphrase } from "@/lib/usePassphrase";
import {
  isStringWithLength,
  isStringWithMinLength,
} from "@/lib/typeHelpers/stringFunctions/isStringWithLength";
import { useStore } from "vuex";
import LSection from "@/components/layout/LSection.vue";

export default {
  name: "ActivateAccountWithToken",
  components: {
    LSection,
    Button,
    LActionError,
    TextInput,
    LFieldError,
    FullName,
    TokenStatus,
  },
  props: {
    activateAccountToken: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const form = ref(null);
    const actionError = ref("");
    const actionMessage = ref("");
    const tokenIsValid = ref(false);
    const store = useStore();
    console.log(!actionMessage.value);
    const showForm = ref(true);
    console.log(showForm);

    const { setMessage, deleteMessage } = useStatusMessage(ACTIVATE_ACCOUNT);
    const { hashPassphrase } = usePassphrase();

    const inputError = reactive({});
    const userAccount = reactive({
      name: null,
      password: null,
      passwordRepeat: null,
      acceptTerms: null,
      acceptPrivacyPolicy: null,
    });
    const isValid = computed(
      () =>
        userAccount.name &&
        userAccount.acceptTerms &&
        userAccount.acceptPrivacyPolicy &&
        Object.values(inputError).every((msg) => msg === "")
    );

    deleteMessage();

    const onNameChange = (value) => (userAccount.name = value.trim());
    const onEmailChange = (value) => (userAccount.email = value.trim());
    const onPasswordChange = (value) => {
      if (!isStringWithMinLength(value)) {
        inputError.password =
          value !== null && !isStringWithMinLength(value)
            ? "Minimum length of 6 characters"
            : "";
      } else {
        inputError.password = "";
        userAccount.password = hashPassphrase(value.trim());
      }
    };

    const onPasswordRepeatChange = (value) =>
      (userAccount.passwordRepeat = hashPassphrase(value.trim()));
    const onAcceptTermsChange = (value) => (userAccount.acceptTerms = value);
    const onAcceptPrivacyPolicyChange = (value) =>
      (userAccount.acceptPrivacyPolicy = value);

    watchPostEffect(() => {
      const {
        name,
        password,
        passwordRepeat,
        acceptTerms,
        acceptPrivacyPolicy,
      } = userAccount;
      inputError.name =
        name !== null && !isStringWithLength(name) ? "Name is required" : "";

      inputError.acceptTerms =
        acceptTerms !== null && !acceptTerms
          ? "You must accept our terms and conditions"
          : "";
      inputError.acceptPrivacyPolicy =
        acceptPrivacyPolicy !== null && !acceptPrivacyPolicy
          ? "You must accept our privacy policy"
          : "";
      inputError.passwordRepeat =
        passwordRepeat !== null && !isStringWithLength(passwordRepeat)
          ? "Please repeat your password"
          : "";
      inputError.passwordRepeat =
        !inputError.passwordRepeat &&
        isStringWithLength(passwordRepeat) &&
        passwordRepeat !== null &&
        passwordRepeat !== password
          ? "Your passwords do not match"
          : "";
    });

    watch(tokenIsValid, (value) => {
      if (value === true)
        setMessage({ isSuccess: true, body: "Your token is valid." });
      else if (value === false)
        setMessage({
          isError: true,
          body: "Your token is invalid. It may have been used before or it may have expired. Please try again.",
        });
    });
    const onSubmit = () => {
      console.log("validating userAccount info");

      if (!isValid.value) return;
      actionError.value = "";
      const options = {
        name: userAccount.name,
        password: userAccount.password,
        activateAccountToken: props.activateAccountToken,
      };
      delete options.passwordRepeat;

      console.log("Requesting new userAccount");
      store.dispatch(ACTIVATE_ACCOUNT, options);
      // reset form
      Object.keys(options).forEach((key) => delete options[key]);
      showForm.value = false;
    };
    const onValidToken = () => {
      tokenIsValid.value = true;
      store.state[ACTIVATE_ACCOUNT].body =
        "Your account is now activated. Please log in.";
    };
    return {
      actionError,
      actionMessage,
      inputError,
      isValid,
      form,
      onSubmit,
      userAccount,
      onNameChange,
      onEmailChange,
      onPasswordChange,
      onPasswordRepeatChange,
      onAcceptTermsChange,
      onAcceptPrivacyPolicyChange,
      onValidToken,
      tokenIsValid,
      showForm,
    };
  },
};
</script>

<style scoped></style>
